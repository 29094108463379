<template>
  <div class="lcView">
    <van-nav-bar :title="$t('稳定理财')" left-arrow @click-left="onClickLeft"  :right-text="$t('购买记录')" @click-right="onClickRight"/>
    <div class="items">
      <h1>{{$t('存储期间')}}</h1>
      <ul>
          <li  v-for="(item, index) in items" :key="index" :class="{ active: activeIndex === index }" @click="setActive(index)"><span>{{item.title}}</span><i>年換算収益率</i><i>{{item.profit}}%</i></li>
         
      </ul>
    </div> 
    <div class="content_box text-center">
      <van-cell-group>
        <van-field
          v-model="money"
          :label="$t('金额')"
           :disabled="activeIndex === null"
          :placeholder="$t('请输入金额(円)')"

        />
      </van-cell-group>
       <div class="profit">({{$t('预估收入')}}{{profit}})</div>
      <van-button type="primary" class="big_other_btn2" @click="submitHandler">{{ $t('提交') }}</van-button>
    </div>
  </div>
</template>
<script>
import { setProfit } from "@/api/users";
import area from "@/utils/area";
export default {
  name: "lc",
  data() {
    return {
      items:[
          {"title":"高級空","profit":"10000"},
          {"title":"中級空","profit":"5000"},
          {"title":"初級空","profit":"3000"}
      ],
      activeIndex: null, // 当前激活的索引
      money:""
    };
  },
  created() {
    this.areaList = area;
  },
  computed: {
    profit() {
      if(this.activeIndex !== null){
           if(this.money==""){
              return "0.00";
           }else{
              return (parseInt(this.money)  * parseInt(this.items[this.activeIndex]['profit']) * 0.01).toFixed(2);
           } 
      }else{
        return  "0.00";
      }
     
    }
  },
  methods: {
    onClickRight(){
      this.$router.push("/main/user/profit_lists");
    },
    setActive(index) {
      this.activeIndex = index; // 设置当前激活的索引
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    submitHandler(e) {
      this.disabled = false;
      if (this.activeIndex === null) {
        return;
      }
      setProfit({money:this.money,title:this.items[this.activeIndex]['title'],profit:this.items[this.activeIndex]['profit']})
        .then(response => {
          this.$toast({
            message: response.msg,
            duration: 500,
            forbidClick: true
          });
          this.disabled = true;
          this.money="";
        })
        .catch(error => {
          this.disabled = false;
        });
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.lcView
  .van-field__label
    color: #ffffff;
  .items 
    padding: 0.3rem;
    color: #ffff;
    h1 
      font-size: 0.32rem;
    ul 
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 0.2rem;
      flex-wrap: wrap; 
      li.active
        border: 0.03rem solid #4ca1da;
        color: #4ca1da;
        background: #eff8ff;
      li 
        border: 0.03rem solid #b2b2b2;
        margin-bottom: 0.1rem;
        border-radius: 0.2rem;
        padding: 0.4rem;
        width: 30%;
        box-sizing: border-box;
        span 
          text-align: center;
          display:block;
          margin-bottom: 0.1rem;
        i
          text-align: center;
          display:block;
  .van-tabs__line
    background-color: #4087f1
  .van-tab__text
    font-size: 0.42rem
    font-weight: bold
  .content_box
    margin: 0.3rem
    .profit
      color: #ffffff;
      margin-top: 0.2rem;
      text-align: left;
      font-size: 0.34rem;
      font-weight: bold;
      padding-left: 0.2rem;
    .mobile
      .van-cell
        padding: 0.26667rem 0.2rem 0.26667rem 0.42667rem
      .verify
        margin-top: 0.28rem
    h2
      color: $grayTextColor
    .van-cell
      background-color: $cellBackColor
      padding: 0.1rem 0.3rem
    .van-icon::before
      color: $grayTextColor
    .van-icon
      transform: rotate(90deg)
    .big_btn
      margin: 0.36rem 0 0 0
</style>
